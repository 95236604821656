@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');

:root {
  --goldColor : #F5C205;
  --mainColor : #014133;
  --lineColor : #00B900;
  --fontTH : Prompt, sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: Prompt, sans-serif;
  background: #000;
}

h1 , h2 , h3 , h4 {
  color: var(--mainColor);
}

/* Line Stick Box */
.line-stick-mobile {
  display: none;
}

button , input {
  font-family: var(--fontTH);
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width : 821px) {
  /* Line Stick Box */
  .line-stick-mobile {
    position: fixed;
    bottom: 140px;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 2.2em;
    border-radius: 50%;
    color: var(--lineColor);
    background: rgba(250, 250, 250, 1);
    z-index: 80;
    transition: 0.3s all ease-in;
  }
} 